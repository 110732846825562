import { ArrayFormatter, Schema as SCH } from '@effect/schema'
import { AccountType } from '@shared/enums'
import { ApplicationNotEnabledBody } from '@shared/schemas/common'
import { Array as A, Either as E, pipe } from 'effect'

import { API } from './aws-api'

export interface ProfileDTO {
  id: string
  email: string
  givenName: string
  familyName: string
  organizationId: string
  organizationName: string
  accountType: AccountType
  isOrderSyndicateEmailsSuppressed: boolean
  isIoiEnabled: boolean
}

export const getUserProfile = async (): Promise<ProfileDTO> => {
  return await API<ProfileDTO>().get({
    path: `/v1/profile`,
  })
}

export async function requestAccess(): Promise<void> {
  void (await API().post({
    path: '/v1/profile/request-access',
  }))
}

export async function postApplicationNotEnabledNotification(pathname: any): Promise<void> {
  const encodedPayload = SCH.encodeEither(ApplicationNotEnabledBody)({
    requestedFrom: pathname,
  })

  if (E.isLeft(encodedPayload)) {
    const encodingErrors = pipe(
      encodedPayload.left,
      ArrayFormatter.formatErrorSync,
      A.map((error) => error.message),
      A.join(' | ')
    )
    throw new Error(encodingErrors)
  }

  void (await API().post({
    path: '/v1/profile/application-not-enabled',
    init: {
      body: encodedPayload.right,
    },
  }))
}
